import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosServices from "../../services/axiosServices";
import { toast } from "react-toastify";
import { createQuery } from "../../utils/createQuery";

export const sendSMS = createAsyncThunk(
  "communication/sendSMS",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v3/sms/send", payload);
      const success = response.success;
      const data = response.result?.data;

      // if (success && data) {
      //   notify(response.message || 'SMS sent successfully', { type: "success" });
      // } else {
      //   notify(response.message || 'An error occured', { type: "error" });
      // }
      return response.result?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const fetchSMSHistory = createAsyncThunk(
  "communication/fetchSMSHistory",
  async (queryParams, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v3/sms/history${createQuery(queryParams)}`
      );
      return response.result?.messages || [];
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);